import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { PromptValue } from '@azure/msal-common';
import { environment } from '@environment';

import { IERPAccountInfo, ISession } from '../interfaces';

import { ERPSessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ERPAuthService {
  constructor(
    private readonly router: Router,
    private readonly sessionService: ERPSessionService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookieService: CookieService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.deleteCookie('JWT-Cookie');
  }

  private sessionData: ISession;
  session$ = new ReplaySubject<ISession>();
  tenantId: string | null = null;

  get session(): ISession {
    return this.sessionData;
  }

  set session(session: ISession) {
    this.sessionData = session;
    this.session$.next(this.sessionData);
  }

  private parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  loadSession() {
    this.sessionService.getSession(this.getCustomUserID()).subscribe(res => (this.session = res));
  }

  clearSession() {
    this.sessionService.deleteSession(this.getCustomUserID()).subscribe(res => (this.session = res));
  }

  msalBroadcastEventMessage: Observable<EventMessage> = this.msalBroadcastService.msalSubject$.pipe(
    filter(
      (msg: EventMessage) =>
        msg.eventType === EventType.LOGIN_SUCCESS ||
        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        msg.eventType === EventType.SSO_SILENT_SUCCESS
    )
  );

  setActiveAccount(account: AccountInfo): void {
    this.authService.instance.setActiveAccount(account);
  }

  setCookie(name: string, cookie: string) {
    this.cookieService.set(name, cookie, {
      secure: true,
      path: '/',
      domain: environment.apiDomain.replace('https://services', '')
    });
  }

  deleteCookie(name: string) {
    this.cookieService.delete(name);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  async login(userFlowRequest?: RedirectRequest | PopupRequest): Promise<void> {
    if (this.msalGuardConfig.authRequest) {
      return firstValueFrom(
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest
        } as RedirectRequest)
      );
    } else {
      return firstValueFrom(this.authService.loginRedirect(userFlowRequest));
    }
  }

  async logout(): Promise<void> {
    this.authService.logoutRedirect();
  }

  getUsername(): string {
    const username = this.account?.username?.trim() ? this.account?.username : null;
    const name = this.account?.name?.trim() ? this.account?.name : null;

    return username ?? name ?? '';
  }

  getCustomUserID(): string {
    return `${this.getUsername()}`;
  }

  getUserDomain(): string {
    return (this.account?.idTokenClaims?.extension_potntid ?? '') as string;
  }

  async loadUserProfile(): Promise<IERPAccountInfo> {
    return this.account as unknown as IERPAccountInfo;
  }

  async updateToken(forceRefresh: boolean = false): Promise<AuthenticationResult> {
    return firstValueFrom(this.acquireTokenSilent(forceRefresh));
  }

  getToken(forceRefresh: boolean = false): Observable<string> {
    return this.acquireTokenSilent(forceRefresh).pipe(
      map((result: AuthenticationResult) => {
        return result.accessToken;
      })
    );
  }

  protected acquireTokenSilent(forceRefresh: boolean = false): Observable<AuthenticationResult> {
    return this.msalBroadcastService.msalSubject$.pipe(
      take(1),
      switchMap(() => this.authService.instance.acquireTokenSilent({ ...this.accessTokenRequest, forceRefresh })),
      tap(
        payload => {
          this.tenantId = payload.tenantId;
          this.deleteCookie('JWT-Cookie');
        },
        error => {
          this.deleteCookie('JWT-Cookie');
        }
      )
    );
  }

  protected get accessTokenRequest(): SilentRequest {
    return {
      scopes: [...environment.apiConfig.scopes],
      account: this.account,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: '',
      prompt: PromptValue.NONE
    };
  }

  protected get account(): AccountInfo {
    return this.authService.instance.getAllAccounts()[0];
  }
}
