import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ERPApiConfigService, PageTitleService } from '@erp/core';
import { combineLatest, from, map, Observable, shareReplay, switchMap, tap } from 'rxjs';

@Component({
  selector: 'erp-file-preview',
  template: `
    <div class="attachment-viewer-header">
      <h3></h3>
      <h3></h3>
      <div class="page">
        <erp-label>Page:</erp-label>
        <erp-number
          [disabled]="!(file$ | async)"
          [(ngModel)]="page"
          [ngModelOptions]="{ standalone: true }"
          type="integer"
          label="Page"
          class="input no-bottom-padding"
        >
        </erp-number>
      </div>

      <div class="page">
        <erp-label>Zoom:</erp-label>
        <erp-button color="white" variant="link" (click)="onPlusZoom()" [disabled]="!(file$ | async)">
          <erp-icon icon="action:add"></erp-icon>
        </erp-button>

        <erp-button color="white" variant="link" (click)="onMinusZoom()" [disabled]="!(file$ | async)">
          <erp-icon icon="action:remove"></erp-icon>
        </erp-button>
      </div>

      <erp-button color="white" variant="link" (click)="onPrintPDFAttachment()" [disabled]="!blob">
        Print
        <erp-icon icon="action:print-file"></erp-icon>
      </erp-button>

      <erp-button color="white" variant="link" (click)="onDownloadAttachment()" [disabled]="!(file$ | async)">
        <erp-icon icon="action:download"></erp-icon>
        Download
      </erp-button>
    </div>

    <mat-card *ngIf="attachmentPreviewError" class="attachment-error col-12">
      <h3>{{ attachmentPreviewError }}</h3>
    </mat-card>

    <div *ngIf="attachment === null" class="no-data-container">
      <erp-empty-list
        headerIcon="tiles:no-file"
        header="No file selected to display"
        i18n-header="@@inventory.material-test-reports.preview.no-file.header"
        content="Upload new file or select existing"
        i18n-content="@@inventory.material-test-reports.preview.no-file.content"
      ></erp-empty-list>
    </div>

    <pdf-viewer
      *ngIf="file$ | async"
      [src]="file$ | async"
      [(page)]="page"
      [rotation]="0"
      [original-size]="true"
      [show-all]="true"
      [fit-to-page]="true"
      [zoom]="zoom"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [render-text-mode]="1"
      [autoresize]="true"
      [show-borders]="true"
      style="width: 100%; height: calc(100% - 70px)"
      (error)="onError($event)"
      (after-load-complete)="onAfterLoadComplete($event)"
    ></pdf-viewer>
  `,
  styleUrls: ['./file-preview.component.scss']
})
export class ERPFilePreViewPageComponent implements OnInit {
  blob: Blob | null = null;
  src = '';
  page = 1;
  zoom = 1;
  attachment: any = {};

  attachmentPreviewError: string | null = null;
  pdfUrl$ = combineLatest([this.$route.queryParams]).pipe(
    map(([{ ep, params }]) => {
      return `${this.$apiConfig.domain}/${decodeURIComponent(ep)}?params=${params}`;
    })
  );

  file$: Observable<string | null> = this.pdfUrl$.pipe(
    switchMap(url => {
      return this.$http.get(url, { responseType: 'blob' }).pipe(
        tap(blob => (this.blob = blob)),
        switchMap(data => this.readFile(data))
      );
    }),
    tap(_ => {
      this.src = _;
    }),
    shareReplay(1)
  );

  constructor(
    private readonly $http: HttpClient,
    private readonly $route: ActivatedRoute,
    private readonly $apiConfig: ERPApiConfigService,
    private readonly $title: PageTitleService
  ) {
    this.$title.setTitle('PDF preview');
  }

  ngOnInit(): void {}

  readFile(data: Blob): Observable<string> {
    return from(
      new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.readAsDataURL(data);
        fr.onloadend = event => {
          resolve(fr.result);
        };

        fr.onerror = () => {
          reject(fr.error);
        };
      })
    ) as Observable<string>;
  }

  onDownloadAttachment() {
    if (!this.blob) {
      return;
    }

    const anchor = document.createElement('a');

    anchor.href = URL.createObjectURL(this.blob);
    anchor.download = 'file.pdf';
    anchor.click();
  }

  onPlusZoom(): void {
    this.zoom += 0.1;
  }

  onMinusZoom(): void {
    this.zoom -= 0.1;
  }

  onError($event: { name: string }) {
    if ($event?.name === 'InvalidPDFException') {
      const err = $localize`:@@components.previewer.error.invalid-pdf:Preview is not available, please download the document.`;
      this.attachmentPreviewError = err;
    }
  }

  onOpenPDFInNewTab(): void {
    window.open(this.src, '_blank');
  }

  onPrintPDFAttachment(): void {
    if (!this.blob) {
      return;
    }
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.src = URL.createObjectURL(this.blob as Blob);

    iframe.onload = function () {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    };
  }

  onAfterLoadComplete(event: Event) {
    this.attachmentPreviewError = null;
  }
}
