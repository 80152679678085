import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ERPNumberModule } from '../number';
import { ERPTooltipModule } from '../tooltip';
import { ERPQuantityInputComponent } from './component/quantity-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, ERPNumberModule, ReactiveFormsModule, ERPTooltipModule, MatIconModule],
  declarations: [ERPQuantityInputComponent],
  exports: [ERPQuantityInputComponent]
})
export class QuantityInputModule {}
