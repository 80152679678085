import { Component, Input } from '@angular/core';

@Component({
  selector: 'erp-empty-list',
  template: `
    <erp-icon [icon]="headerIcon" size="big"></erp-icon>
    <h2>{{ header }}</h2>
    <p>{{ content }}</p>

    <erp-button *ngIf="actionLabel" color="primary" [disabled]="actionDisabled" (click)="action()">
      <erp-icon *ngIf="actionIcon" [icon]="actionIcon"></erp-icon>
      {{ actionLabel }}
    </erp-button>
  `,
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent {
  @Input() header: string = '';
  @Input() headerIcon: string = 'tiles:masonry-tiles';
  @Input() content: string = '';
  @Input() action: () => void | null;
  @Input() actionLabel: string | null;
  @Input() actionIcon: string | null;
  @Input() actionDisabled: boolean = false;
}
