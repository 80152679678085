import { Observable, switchMap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BaseHttpService } from '../base-http.service';
import { ERPSettingsService } from '../settings';

@Injectable({
  providedIn: 'root'
})
export class ERPUomService extends BaseHttpService {
  private readonly $settings: ERPSettingsService = inject(ERPSettingsService);

  constructor(readonly httpClient: HttpClient) {
    super('unitsofmeasure');
  }

  convertSkuMeasurement(params: {
    skuId: string;
    quantity: number;
    uomFromId: number;
    uomToId: number;
    manufacturerId?: number | number[];
    materialId?: number;
    convertionRate?: number | null;
  }): Observable<number> {
    return this.$settings.uoms().pipe(
      switchMap(uoms => {
        return this.get<number>(
          {
            ...params,
            rateFromUomId: uoms.count,
            rateToUomId: uoms.length
          },
          'converters/countables/pipes'
        );
      })
    );
  }
}
