import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ERPPDPRolesService, ERPReportsService, IPDPRoles, IReportMetadata, ReportCategories } from '@erp/shared';

import { ERPLogisticLoadingReportComponent } from '../logistic-loading-report/logistic-loading-report.component';
import { ERPOnHandReportComponent } from '../on-hand-report';
import { ERPLogisticReportsService } from './logistic-reports.service';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'erp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPLogisticReportsService]
})
export class ERPSidenavListComponent {
  @Input() roles: IPDPRoles;

  readonly prefix = 'side-menu';
  allReports: IReportMetadata[];

  constructor(
    private readonly logisticReportService: ERPLogisticReportsService,
    readonly pdpService: ERPPDPRolesService,
    readonly reportsService: ERPReportsService
  ) {}

  reports$ = this.reportsService.getReportsList().pipe(shareReplay(1));

  filterOutReports = (reportCategory: ReportCategories) => {
    return map((data: IReportMetadata[]) => {
      return data.filter(report => report.categoryName === reportCategory);
    });
  };
  logisticAndWarehousingReports$ = this.reports$.pipe(this.filterOutReports(ReportCategories.LogisticsAndWarehousing));
  salesReports$ = this.reports$.pipe(this.filterOutReports(ReportCategories.Sales));
  procurementReports$ = this.reports$.pipe(this.filterOutReports(ReportCategories.Procurement));
  productionReports$ = this.reports$.pipe(this.filterOutReports(ReportCategories.Production));
  inventoryReports$ = this.reports$.pipe(this.filterOutReports(ReportCategories.Inventory));

  onShippingReport() {
    this.logisticReportService.onShowShippingReport(ERPLogisticLoadingReportComponent);
  }

  onReceivingReport() {
    this.logisticReportService.onShowReceivingReport(ERPLogisticLoadingReportComponent);
  }

  onOnHandReport() {
    this.logisticReportService.onShowOnHandReport(ERPOnHandReportComponent);
  }

  onShippingPackageReport() {
    this.logisticReportService.onShowShippingPackageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReport() {
    this.logisticReportService.onShowStorageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReportFTZ() {
    this.logisticReportService.onShowStorageReportFTZ(ERPLogisticLoadingReportComponent);
  }
}
