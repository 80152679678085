import { Observable } from 'rxjs';

import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

import { AutoCleanupFeature, Features, IDestroyable } from '../../../../../../../shared/src/lib';

const AUTOSCROLL_TIMEOUT = 500;
const FETCH_ELEMENT_TIMEOUT = 1000;
const PROMISE_TIMEOUT = 4000;

@Component({
  selector: 'erp-sidenav-expansion-panel',
  templateUrl: './sidenav-expansion-panel.component.html',
  styleUrls: ['./sidenav-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
@Features([AutoCleanupFeature()])
export class ERPSidenavExpansionPanelComponent implements AfterViewInit, AfterContentChecked, IDestroyable {
  destroyed$: Observable<unknown>;
  @Input() readonly header: string;
  @Input() expanded = false;
  private disabledValue = false;
  @Input() set disabled(input: boolean) {
    if (this.panel) {
      this.panel.disabled = input;
    }
    this.disabledValue = input;
  }

  @ViewChild('matExpansionPanel') panel: MatExpansionPanel;

  hasActive = false;
  isShow = false;
  private scrollPendingItem: HTMLElement | undefined;

  constructor(private changeDetector: ChangeDetectorRef) {}
  ngAfterContentChecked(): void {
    this.hasActive = !!this.getActiveItem();
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isShow = true;
      if (this.panel) {
        this.panel.disabled = this.disabledValue;
      }
      this.changeDetector.detectChanges();

      this.tryGetActiveElement().then((item: HTMLElement | null) => {
        if (item instanceof HTMLElement) {
          this.handleElement(item);
        }
      });
    });
  }

  onToggle(value: boolean) {
    this.expanded = value;
    value ? this.panel.open() : this.panel.close();
  }

  private handleElement(item: HTMLElement): void {
    this.scrollPendingItem = item;
    if (!this.scrollPendingItem) {
      return;
    }
    this.onToggle(true);
    this.changeDetector.detectChanges();

    this.setScrollForActiveElement();
  }

  private getActiveItem(): HTMLElement {
    return this.panel?._body?.nativeElement.querySelectorAll('.mat-list-item.active')[0] as HTMLElement;
  }

  private tryGetActiveElement(): Promise<HTMLElement | null> {
    return new Promise(resolve => {
      const activeItem = this.getActiveItem();
      if (activeItem) {
        resolve(activeItem);
      }

      // try to get active element every 1 second
      const interval = setInterval(() => {
        const item = this.getActiveItem();
        if (item) {
          resolve(item);
        }
      }, FETCH_ELEMENT_TIMEOUT);

      // clear timer after 4 seconds
      setTimeout(() => {
        resolve(null);
        clearInterval(interval);
      }, PROMISE_TIMEOUT);
    });
  }

  private setScrollForActiveElement(): void {
    // Timeout is set since there is no simple way of detecting if all items are rendered
    // and scrollIntoView won't work before that. Investigations postponed (KO)
    setTimeout(() => {
      const rect = this.scrollPendingItem?.getBoundingClientRect();
      if (!rect || rect?.bottom < window.innerHeight) {
        return;
      }
      this.scrollPendingItem?.scrollIntoView();
    }, AUTOSCROLL_TIMEOUT);
  }
}
