import { Observable } from 'rxjs/internal/Observable';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

export enum ReportCategories {
  LogisticsAndWarehousing = 'LogisticsAndWarehousing',
  Sales = 'Sales',
  Procurement = 'Procurement',
  Production = 'Production',
  Inventory = 'Inventory'
}

export interface IReportMetadata {
  id: string;
  name: string;
  categoryName: ReportCategories;
}

@Injectable({ providedIn: 'root' })
export class ERPReportsService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('');
  }

  protected get baseUrl() {
    return `${this.apiConfigService.domain}/gateway/api/dashboard/dashboards`;
  }

  getReportsList(): Observable<IReportMetadata[]> {
    return this.get<IReportMetadata[]>({}, this.baseUrl, {}, true);
  }
}
