import { Observable, map, mapTo, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../base-http.service';
import { EInventoryStatuses } from './types/environment-statuses';
import { IDefaultOwner } from './types/defailt-owner';
// TODO: avoid direct imports from shared in the api
import { IdValue, Cache } from '../../../../shared';
import { IDefaultUoms } from './types/uoms';

@Injectable({
  providedIn: 'root'
})
export class ERPSettingsService extends BaseHttpService {
  private _defaultLocation = '';

  constructor(readonly httpClient: HttpClient) {
    super('settings');
  }

  @Cache()
  getDefaultLocation(params = {}) {
    return this.get<IdValue>(params, 'location');
  }

  getDefaultOwner(params = {}): Observable<IDefaultOwner> {
    return this.get<IDefaultOwner>(params, `owner`);
  }

  getFTZLocation(): Observable<IdValue<EInventoryStatuses>> {
    return this.get<IdValue<EInventoryStatuses>>({}, 'inventoryStatus/ftz');
  }

  get defaultLocation(): string {
    return this._defaultLocation;
  }

  @Cache()
  uoms() {
    return this.get<IDefaultUoms>({}, 'uoms');
  }

  @Cache()
  onLoadDefaultLocation(): Observable<string> {
    return this.getDefaultLocation().pipe(
      map((location: IdValue | null) => location?.value ?? ''),
      tap((location: string) => (location ? (this._defaultLocation = location) : null)),
      mapTo('')
    );
  }
}
