import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IProductionProcessingOrderResponse } from './work-order.interface';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base-http.service';

@Injectable({ providedIn: 'root' })
export class ERPWorkOrderService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('processingOrders');
  }

  getWorkOrder(id: string | number, skipLoader = false): Observable<IProductionProcessingOrderResponse> {
    const options = skipLoader ? { headers: { 'skip-loader': 'true' } } : {};

    return this.get<IProductionProcessingOrderResponse>({}, id, options);
  }
}
