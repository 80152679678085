import { Injectable } from '@angular/core';
import { BaseHttpService, ICollectionResponse } from '@erp/shared';
import { HttpClient } from '@angular/common/http';
import { IMasterWorkOrder, IMasterWorkOrderListResponse } from './master-work-order.interface';

@Injectable({ providedIn: 'root' })
export class ERPMasterWorkOrderService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('masterWorkOrders');
  }

  getMasterWorkOrders(params: object) {
    return this.get<ICollectionResponse<IMasterWorkOrderListResponse>>(params);
  }

  createOrder(request: IMasterWorkOrder) {
    return this.post<IMasterWorkOrder>(request);
  }

  getMasterWorkOrder(id: number) {
    return this.get<IMasterWorkOrder>({}, `${id}`);
  }

  updateMasterWorkOrder(document: IMasterWorkOrder) {
    return this.put<IMasterWorkOrder>(document, {}, `${document.id}`);
  }

  deleteMasterWorkOrder(id: number) {
    return this.delete({}, `${id}`);
  }
}
