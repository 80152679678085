import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ERPNotificationListenerService, ERPToasterOutletComponent, ERPToasterService } from '@erp/components';
import { ERPUOMDefinitionService, PageTitleService } from '@erp/core';
import { ERPSettingsService } from '@erp/api';
import { ERPSignalRConnectionService, changeSvgPaths } from '@erp/shared';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'erp-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPAppComponent implements OnInit, OnDestroy {
  readonly destroy$: Subject<void> = new Subject<void>();

  @ViewChild(ERPToasterOutletComponent, {
    static: true
  })
  readonly toaster: ERPToasterOutletComponent;
  readonly tabletBodyClass = 'erp-app-tablet';

  @HostBinding('class.erp-tablet-module')
  isTabletModule = false;

  constructor(
    readonly toasterService: ERPToasterService,
    readonly titleService: PageTitleService,
    readonly router: Router,
    readonly zone: NgZone,
    readonly renderer: Renderer2,
    readonly notificationListener: ERPNotificationListenerService,
    private readonly $msal: MsalService,
    private readonly $msalBroadcast: MsalBroadcastService,
    private readonly $settings: ERPSettingsService,
    private readonly $signalR: ERPSignalRConnectionService
  ) {}

  ngOnInit() {
    (window as Window & typeof globalThis & { logout(): void }).logout = this.$msal.logoutRedirect.bind(this.$msal);
    this.$msal.instance.enableAccountStorageEvents();
    this.startAuthorizerActivities();

    this.toasterService.setDefaultContainer(this.toaster);
    this.notificationListener.initListener();

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.isTabletModule = this.router.url.startsWith('/tablet');
        if (this.isTabletModule) {
          this.renderer.addClass(document.body, this.tabletBodyClass);
        } else {
          this.renderer.removeClass(document.body, this.tabletBodyClass);
        }

        this.zone.runOutsideAngular(changeSvgPaths);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  startAuthorizerActivities() {
    this.$msalBroadcast.msalSubject$
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        distinctUntilChanged((prev: any, curr: any) => {
          return prev?.payload?.accessToken === curr?.payload?.accessToken;
        }),
        tap(_ => {
          this.$settings.onLoadDefaultLocation().subscribe();
          this.$settings.uoms().subscribe();
          this.$signalR.initConnection();
        })
      )
      .subscribe();
  }
}
