import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { ERPUOMDefinitionService } from '@erp/core';
import { distinctUntilChanged, map, Observable, shareReplay, startWith, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'erp-quantity-input',
  template: `<erp-number
    type="decimal"
    (changed)="changed.emit($event)"
    (focusout)="focusout.emit($event)"
    [format]="format$ | async"
    [formControl]="control"
  >
    <mat-icon *ngIf="allowRecalculate" suffix (click)="recalculate.emit()" erpTooltip="Recalculate quantity"
      >sync</mat-icon
    >
  </erp-number>`,
  styles: [
    `
      span: {
      }
    `
  ]
})
export class ERPQuantityInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private UOM_ID: number | null = null;
  private destroy$ = new Subject<void>();
  control = new UntypedFormControl();
  @Input() allowRecalculate = false;
  @Output() changed = new EventEmitter();
  @Output() focusout = new EventEmitter();
  @Output() recalculate = new EventEmitter();

  format$: Observable<string>;
  symbol$: Observable<string>;

  constructor(
    public readonly $ctrl: NgControl,
    private readonly $uomDefinitions: ERPUOMDefinitionService
  ) {
    this.$ctrl.valueAccessor = this;
  }

  onChange = (value: any) => null;
  onTouched = () => null;

  writeValue(obj: any): void {
    if (this.control.value === obj.value) {
      return;
    }

    this.control.setValue(obj?.value || null, { emitEvent: false });
    this.UOM_ID = obj?.uomId || null;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  ngOnInit(): void {
    this.control.setValidators(this.$ctrl.validator);
    this.control.setAsyncValidators(this.$ctrl.asyncValidator);

    this.format$ = this.$ctrl.control?.valueChanges.pipe(
      startWith(this.$ctrl.control.value),
      map((value: any) => this.$uomDefinitions.getPrecision(value.uomId)),
      distinctUntilChanged(),
      shareReplay(1)
    ) as Observable<string>;

    this.symbol$ = this.$ctrl.control?.valueChanges.pipe(
      startWith(this.$ctrl.control.value),
      map((value: any) => this.$uomDefinitions.getUomById(value.uomId)?.symbol || ''),
      distinctUntilChanged(),
      shareReplay(1)
    ) as Observable<string>;

    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.onChange({ value, uomId: this.UOM_ID }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
