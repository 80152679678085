import { Inject, Injectable } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { ERPApiConfigService } from './api-config.service';

@Injectable()
export class ReportOpenerService {
  constructor(
    private readonly $apiConfig: ERPApiConfigService,
    @Inject(DOCUMENT) private readonly $document: Document
  ) {}

  open(url: string) {
    const previewPath = 'file/pdf';
    const [path, params] = url.split('?');
    window.open(`${this.$document.baseURI}${previewPath}?ep=${path}&${params}`, '_blank');
  }
}
