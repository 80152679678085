import { NgModule } from '@angular/core';
import { ERPFilePreViewPageComponent } from './file-preview.component';
import { ERPButtonModule, ERPEmptyListModule, ERPIconModule, ERPLabelModule, ERPNumberModule } from '@erp/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [ERPFilePreViewPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyCardModule,
    PdfViewerModule,
    ERPEmptyListModule,
    ERPIconModule,
    ERPLabelModule,
    ERPNumberModule,
    ERPButtonModule,
    RouterModule.forChild([{ path: ':type', component: ERPFilePreViewPageComponent }]),
    HttpClientModule
  ],
  exports: [RouterModule, HttpClientModule]
})
export class ERPFilePreviewModule {
  constructor() {}
}
