import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';

import { DefaultQueryEncoder } from './default-query.encoder';
import { inject } from '@angular/core';
import { ERPApiConfigService } from '../../../core/src/lib/services/api-config.service';

export abstract class BaseHttpService {
  protected readonly encoder: HttpParameterCodec;
  protected abstract readonly httpClient: HttpClient;
  protected apiConfigService: ERPApiConfigService;

  constructor(private path: string) {
    this.apiConfigService = inject(ERPApiConfigService);
    this.encoder = new DefaultQueryEncoder();
  }

  protected get baseUrl() {
    return `${this.apiConfigService.url}${this.path}`;
  }

  get<TResponse>(params?: {}, path: string | number = '', config?: { [key: string]: any }, rewriteUrl = false) {
    const url = rewriteUrl ? path.toString() : `${this.baseUrl}/${path}`;
    let headers = new HttpHeaders();

    Object.entries({
      ...(config?.headers || {}),
      'Access-Control-Allow-Origin': '*'
    }).forEach(([header, value]) => {
      headers = headers.append(header, value as string);
    });

    return this.httpClient.get<TResponse>(url, {
      ...config,
      headers,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  delete<TResponse>(params?: {}, path: string | number = '', config?: { [key: string]: any }) {
    const url = `${this.baseUrl}/${path}`;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');

    return this.httpClient.delete<TResponse>(url, {
      ...config,
      headers,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  post<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: { [key: string]: any }
  ) {
    const url = `${this.baseUrl}/${path}`;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');

    return this.httpClient.post<TResponse>(url, body, {
      ...config,
      headers,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  put<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: { [key: string]: any }
  ) {
    const url = `${this.baseUrl}/${path}`;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');

    return this.httpClient.put<TResponse>(url, body, {
      ...config,
      headers,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  patch<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: { [key: string]: any }
  ) {
    const url = `${this.baseUrl}/${path}`;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');

    return this.httpClient.patch<TResponse>(url, body, {
      ...config,
      headers,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  request<TRequest, TResponse = TRequest>(request: HttpRequest<TRequest>) {
    return this.httpClient.request<TResponse>({
      params: new HttpParams({
        fromObject: request.params as {},
        encoder: this.encoder
      })
    } as HttpRequest<unknown>);
  }
}
